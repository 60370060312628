import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "crafted-reading---jamming-on-value",
      "style": {
        "position": "relative"
      }
    }}>{`Crafted Reading - Jamming On Value`}<a parentName="h1" {...{
        "href": "#crafted-reading---jamming-on-value",
        "aria-label": "crafted reading   jamming on value permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "-of-mirrorworlds-and-metaverses",
      "style": {
        "position": "relative"
      }
    }}>{`🌌 Of Mirrorworlds and Metaverses`}<a parentName="h2" {...{
        "href": "#-of-mirrorworlds-and-metaverses",
        "aria-label": " of mirrorworlds and metaverses permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <List mdxType="List">
      <Link to="https://www.wired.com/story/mirrorworld-ar-next-big-tech-platform/" mdxType="Link">
        <h4 {...{
          "id": "call-it-mirrorworld",
          "style": {
            "position": "relative"
          }
        }}>{`Call It Mirrorworld`}<a parentName="h4" {...{
            "href": "#call-it-mirrorworld",
            "aria-label": "call it mirrorworld permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`The vision of a unified virtual world has been in our imaginations for decades. In this article Kevin Kelly calls it `}<strong parentName="p">{`Mirrorworld`}</strong>{`, as popularized by David Gelernter in the 90s.`}</p>
      </Link>
      <Link to="https://www.forbes.com/sites/cathyhackl/2020/07/05/the-metaverse-is-coming--its-a-very-big-deal/?sh=1781cf2d440f" mdxType="Link">
        <h4 {...{
          "id": "the-metaverse-is-coming-1",
          "style": {
            "position": "relative"
          }
        }}>{`The Metaverse is Coming 1`}<a parentName="h4" {...{
            "href": "#the-metaverse-is-coming-1",
            "aria-label": "the metaverse is coming 1 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Call it Mirrorworld or Metaverse, but it has always been a long time coming, with articles similar to this appearing every few years.`}</p>
      </Link>
      <Link to="https://www.wired.co.uk/article/metaverse" mdxType="Link">
        <h4 {...{
          "id": "the-metaverse-is-coming-2",
          "style": {
            "position": "relative"
          }
        }}>{`The Metaverse is Coming 2`}<a parentName="h4" {...{
            "href": "#the-metaverse-is-coming-2",
            "aria-label": "the metaverse is coming 2 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`This second article is written by David Baszucki: the CEO of Roblox, one of the gaming platforms aiming to become the Metaverse.`}</p>
      </Link>
      <Link to="https://medium.com/the-challenge/state-of-the-metaverse-2021-9f032fed655b" mdxType="Link">
        <h4 {...{
          "id": "state-of-the-metaverse-2021",
          "style": {
            "position": "relative"
          }
        }}>{`State of the Metaverse 2021`}<a parentName="h4" {...{
            "href": "#state-of-the-metaverse-2021",
            "aria-label": "state of the metaverse 2021 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`This is a helpful primer on the current technologies and standards that might help shape the future Metaverse. It discusses wide-ranging technologies including AR, VR, AI and crypto.`}</p>
      </Link>
      <Link to="https://tonysheng.substack.com/p/the-virtual-worlds-future-is-already" mdxType="Link">
        <h4 {...{
          "id": "the-virtual-worlds-future-is-already-here",
          "style": {
            "position": "relative"
          }
        }}>{`The Virtual Worlds Future is Already Here`}<a parentName="h4" {...{
            "href": "#the-virtual-worlds-future-is-already-here",
            "aria-label": "the virtual worlds future is already here permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Or perhaps the Metaverse is already here, and it's just not evenly distributed? Tony Sheng once again gives us a helpful, complementary opposite view.`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "-industry-references",
      "style": {
        "position": "relative"
      }
    }}>{`📋 Industry References`}<a parentName="h2" {...{
        "href": "#-industry-references",
        "aria-label": " industry references permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <List mdxType="List">
      <Link to="https://gamasutra.com/" mdxType="Link">
        <h4 {...{
          "id": "gamasutra",
          "style": {
            "position": "relative"
          }
        }}>{`Gamasutra`}<a parentName="h4" {...{
            "href": "#gamasutra",
            "aria-label": "gamasutra permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`The art and business of video games. A good source of research for older game industry trends.`}</p>
      </Link>
      <Link to="https://www.gamesindustry.biz/" mdxType="Link">
        <h4 {...{
          "id": "gamesindustrybiz",
          "style": {
            "position": "relative"
          }
        }}>{`gamesindustry.biz`}<a parentName="h4" {...{
            "href": "#gamesindustrybiz",
            "aria-label": "gamesindustrybiz permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A reliable source of news with a pulse on the whole video games industry.`}</p>
      </Link>
    </List>
    <p>{`Please contribute more with a PR! 📝`}</p>
    <h2 {...{
      "id": "breakout-activity",
      "style": {
        "position": "relative"
      }
    }}>{`Breakout Activity`}<a parentName="h2" {...{
        "href": "#breakout-activity",
        "aria-label": "breakout activity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`For this weekend, we're inviting the Kernel Gaming Track fellows to participate in Global Game Jam 2021: the largest game creation event taking place around the globe. `}</p>
    <p>{`Please register for the Kernel Gaming Track Global Game Jam `}<Link to="https://globalgamejam.org/2021/jam-sites/kernel-gaming-guild-game-jam-2021" mdxType="Link">{`here`}</Link>{`.`}</p>
    <p>{`Fellows will create a game over the weekend based on an announced theme. The theme will be announced at the start of the jam (Friday) and will be shared on Slack.`}</p>
    <p>{`Games don't `}<em parentName="p">{`have`}</em>{` to be video games; they can be anything from a board game or a card game, as long as it adheres to the theme. It might also be a good exercise to design a game that explores Web 3 concepts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      